import {
  Outlet,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";

import $ from "jquery";
import { useEffect } from "react";
import Dashboard from "../tables/dashboard/Dashboard";
import User from "../tables/user/User";
import Banner from "../tables/banner/Banner";
import Category from "../tables/category/Category";
import Attributes from "../tables/attributes/Attributes";
import Product from "../tables/product/Product";
import ProductAdd from "../tables/product/ProductAdd";
import ProductShow from "../tables/product/ProductShow";
import Budget from "../tables/budget/Budget";
import Order from "../tables/order/Order";
import OrderShow from "../tables/order/OrderShow";
import Policy from "../tables/policy/Policy";
import Payment from "../tables/payment/Payment";
import OrderPending from "../tables/order/OrderPending";
import OrderConfirm from "../tables/order/OrderConfirm";
import OrderDeliverd from "../tables/order/OrderDeliverd";
import OrderCanceled from "../tables/order/OrderCanceled";
import AdminProfile from "../tables/admin/AdminProfile";
import ImageSetting from "../tables/setting/ImageSetting";
import WebSetting from "../tables/setting/WebSetting";
import GeneralSetting from "../tables/setting/GeneralSetting";
import UserDetails from "../tables/user/UserDetails";
import ProductCare from "../tables/productCare/ProductCare";
import OrderCancelltion from "../tables/order/OrderCancelltion";
import OrderOutOfDeliverd from "../tables/order/OrderOutOfDeliverd";

const Admin = ({ isAuthenticated }) => {

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (
      location.pathname == "/" ||
      location.pathname == "/admin" ||
      location.pathname == "/admin/" ||
      location.pathname == ""
    ) {
      navigate("/admin/dashboard");
    }
  }, []);
  var webSize = $(window).width();

  return (
    <div className={`mainAdminGrid  ${webSize < 991 && "webAdminGrid"}`}>
      <Sidebar />

      <div className={`mainAdmin`}>
        <Navbar />
        <div className="adminStart">
          <Routes>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/user" element={<User />} />
            <Route path="/user/userDetails" element={<UserDetails />} />
            <Route path="/banner" element={<Banner />} />
            <Route path="/budget" element={<Budget />} />
            <Route path="/category" element={<Category />} />
            <Route path="/attibute" element={<Attributes />} />
            <Route path="/product/*" element={<Product />} />
            <Route path="/product/addProduct" element={<ProductAdd />} />
            <Route path="/product/productShow" element={<ProductShow />} />
            {/* Order Nav Start */}
            <Route path="/order" element={<Order />} />
            <Route path="/order/orderShow" element={<OrderShow />} />
            <Route path="/orderPending" element={<OrderPending />} />
            <Route path="/orderConfirm" element={<OrderConfirm />} />
            <Route path="/orderOutOfDeliverd" element={<OrderOutOfDeliverd />} />
            <Route path="/orderDeliverd" element={<OrderDeliverd />} />
            <Route path="/orderCancelltion" element={<OrderCancelltion />} />
            <Route path="/orderCanceled" element={<OrderCanceled />} />
            {/* Order Nav End */}
            <Route path="/payment" element={<Payment />} />
            <Route path="/policy" element={<Policy />} />
            <Route path="/productCare" element={<ProductCare />} />
            <Route path="/adminProfile" element={<AdminProfile />} />
            {/* Setting Start */}
            <Route path="/imageSetting" element={<ImageSetting />} />
            <Route path="/webSetting" element={<WebSetting />} />
            <Route path="/generalSetting" element={<GeneralSetting />} />
            {/* Setting End */}
          </Routes>




          {/* javascript:(function%20()%20%7B%20var%20script%20=%20document.createElement('script');%20script.src=%22//cdn.jsdelivr.net/npm/eruda%22;%20document.body.appendChild(script);%20script.onload%20=%20function%20()%20%7B%20eruda.init()%20%7D%20%7D)(); */}
        </div>
      </div>
    </div>
  );
};

export default Admin;
