import React, { useEffect, useState } from "react";
import Icon1 from "../../../assets/icons/user.png";
import Icon2 from "../../../assets/icons/order.png";
import Icon3 from "../../../assets/icons/earn.png";
import Icon4 from "../../../assets/icons/product.png";
import ReactApexChart from "react-apexcharts";
import $ from "jquery";
import { useDispatch, useSelector } from "react-redux";
import { dashboardGet } from "../../../redux/slice/dashboardSlice";
import { Link } from "react-router-dom";

const Dashboard = () => {
  const { dashboard } = useSelector((state) => state.dashboard);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(dashboardGet());
  }, []);

  return (
    <div className="mainDashboard">
      <div className="dashBoardHead">
        <h3 className="m3-bottom">Welcome Admin!</h3>
        <p>Dashboard</p>
      </div>
      <div className="mainDashbox">
        <div className="row">
          <div className="col-lg-6 col-12 m25-bottom">
            <div className="row m30-bottom">
              <div className="col-12 m10-bottom">
                <DashBox
                  title={`Total Order`}
                  count={dashboard?.order?.allOrder || 0}
                  img={Icon2}
                  name={`Order`}
                  link={`/order`}
                />
              </div>
              <div className="col-6 m10-bottom">
                <DashBox
                  title={`Pending Order`}
                  count={dashboard?.order?.pandingOrder || 0}
                  link={`/orderPending`}
                />
              </div>
              <div className="col-6 m10-bottom">
                <DashBox
                  title={`Confirm Order`}
                  count={dashboard?.order?.confirmOrder || 0}
                  link={`/orderConfirm`}
                />
              </div>
              <div className="col-6 m10-bottom">
                <DashBox
                  title={`Delivered Order`}
                  count={dashboard?.order?.deliverdOrder || 0}
                  link={`/orderDeliverd`}
                />
              </div>
              <div className="col-6 m10-bottom">
                <DashBox
                  title={`Cencled Order`}
                  count={dashboard?.order?.CencalOrder || 0}
                  link={`/orderCanceled`}
                />
              </div>
              <div className="col-12">
                <DashBox
                  title={`Total Return Order`}
                  count={dashboard?.order?.returnOrder || 0}
                  link={`/orderReturn`}
                />
              </div>
            </div>
          </div>

          <div className="col-lg-6 col-12">
            <div className="row">
              <div className="col-sm-6 col-12 m25-bottom">
                <DashBox
                  title={`Total Users`}
                  count={dashboard?.totalUser || 0}
                  img={Icon1}
                  name={`User`}
                  link={`/user`}
                />
              </div>
              <div className="col-sm-6 col-12">
                <DashBox
                  title={`Total Earn`}
                  count={dashboard?.totalEarn || 0}
                  img={Icon3}
                  name={`Earn`}
                  link={`/payment`}
                />
              </div>

              <div className="col-sm-6 col m25-bottom">
                <DashBox
                  title={`Total Product`}
                  count={dashboard?.product?.allProduct || 0}
                  img={Icon4}
                  name={`Product`}
                  link={`/product`}
                />
              </div>
              <div className="col-sm-6 col m10-bottom">
                <DashBox
                  title={`Total Color Product`}
                  count={dashboard?.product?.totalProduct || 0}
                  img={Icon4}
                  name={`Product`}
                  link={`/product`}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="mainChart m40-top">
        <div className="row">
          <div className="col-12">
            <ApexChart />
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default Dashboard;

const DashBox = ({ title, rate, count, img, name, link }) => {
  return (
    <div className="dashBox">
      <div className="boxHead betBox">
        <p>{title}</p>
        <p className="text-success">{rate}</p>
      </div>
      <div className="boxBody">
        <h2 className="m0">{count}</h2>
      </div>
      <div className="boxFooter">
        <div className="boxLink">
          <Link to={`/admin${link}`}>View All {name}</Link>
        </div>
        {img && (
          <div className="boxIcon">
            <img src={img} alt="" />
          </div>
        )}
      </div>
    </div>
  );
};

const ApexChart = () => {
  var webSize = $(window).width();
  const resHeight =
    webSize >= 992 ? 500 : webSize < 992 && webSize > 576 ? 400 : 300;

  const [chartData, setChartData] = useState({
    series: [
      {
        name: "series1",
        data: [31, 40, 28, 51, 42, 109, 200],
        color: "#394149",
      },
      {
        name: "series2",
        data: [11, 32, 45, 32, 34, 52, 41],
        color: "#A86464",
      },
    ],
    options: {
      chart: {
        type: "area",
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
      },
      xaxis: {
        type: "datetime",
        categories: [
          "2018-09-19T00:00:00.000Z",
          "2018-09-19T01:30:00.000Z",
          "2018-09-19T02:30:00.000Z",
          "2018-09-19T03:30:00.000Z",
          "2018-09-19T04:30:00.000Z",
          "2018-09-19T05:30:00.000Z",
          "2018-09-19T06:30:00.000Z",
        ],
      },
      tooltip: {
        x: {
          format: "dd/MM/yy HH:mm",
        },
      },
    },
  });

  return (
    <div id="chart">
      <ReactApexChart
        options={chartData.options}
        series={chartData.series}
        type="area"
        height={resHeight}
      />
    </div>
  );
};
