import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Input from "../../extra/Input";
import Button from "../../extra/Button";
import { editData, objectToFormData, submitData } from "../../util/fuction";
import { closeDialog } from "../../../redux/slice/dialogSlice";
import { userUpdate } from "../../../redux/slice/userSlice";

const UserEdit = () => {

  const { dialogueData } = useSelector(
    (state) => state.dialogue
  );

  useEffect(() => {
    if (dialogueData) {
      editData(dialogueData);
    }
  }, [dialogueData]);




  const handleSubmit = async (e) => {
    const updateUser = submitData(e);
    if (updateUser) {

      const formData = objectToFormData(updateUser);

      if (dialogueData) {
        const payload = { formData, userId: dialogueData._id }
        await dispatch(userUpdate(payload));
        dispatch(closeDialog())
      }
    }

  };

  const dispatch = useDispatch();
  return (
    <div className="dialog">

      <div className="w-100">
        <div className="row justify-content-center">
          <div className="col-xl-5 col-md-8 col-11">
            <div className="mainDiaogBox">
              <div className="row justify-content-between align-items-center formHead">
                <div className="col-8">
                  <h2 className="text-second m0">User Dialog</h2>
                </div>
                <div className="col-4">
                  <div
                    className="closeButton"
                    onClick={() => {
                      dispatch(closeDialog());
                    }}
                  >
                    <i className="ri-close-line"></i>
                  </div>
                </div>
              </div>
              <form onSubmit={handleSubmit} id="userForm">


                <div className="row align-items-start formBody">
                  <div className="col-xl-6 col-md-12 col-sm-6 col-12">
                    <Input
                      type={`text`}
                      id={`email`}
                      name={`email`}
                      label={`Email`}
                      errorMessage={`Enter Email`}
                    />
                  </div>
                  <div className="col-xl-6 col-md-12 col-sm-6 col-12">
                    <Input
                      type={`text`}
                      id={`name`}
                      name={`name`}
                      label={`Name`}
                      errorMessage={`Enter Name`}
                    />
                  </div>
                  <div className="col-xl-6 col-md-12 col-sm-6 col-12">
                    <Input
                      type={`number`}
                      id={`mobileNo`}
                      name={`mobileNo`}
                      label={`Mobile Number`}
                      errorMessage={`Enter Mobile Number`}
                    />
                  </div>

                  <div className="col-xl-6 col-md-12 col-sm-6 col-12">
                    <Input
                      type={`password`}
                      id={`password`}
                      name={`password`}
                      label={`password`}
                      errorMessage={`Enter password`}
                    />
                  </div>
                  <div className="col-xl-6 col-md-12 col-sm-6 col-12">
                    <Input
                      type={`file`}
                      id={`profileImage`}
                      name={`profileImage`}
                      label={`Profile Image`}
                      errorMessage={`Enter Profile Image`}
                    />
                  </div>
                  <div className="col-3 align-self-center">
                    <Input
                      type={`radio`}
                      id={`male`}
                      name={`gender`}
                      label={`Male`}
                      value={`male`}
                    />
                  </div>
                  <div className="col-3  align-self-center">
                    <Input
                      type={`radio`}
                      id={`female`}
                      name={`gender`}
                      label={`Female`}
                      value={`female`}
                    />
                  </div>

                </div>
                <div className="row m20-top formFooter">
                  <div className="col-12 text-end m0">
                    <Button className={`bg-gray text-light`} text={`Cancel`} type={`button`} onClick={() => dispatch(closeDialog())} />
                    <Button
                      type={`submit`}
                      className={`bg-second text-light m10-left`}
                      text={`Submit`}
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserEdit;
