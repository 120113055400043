import { configureStore } from "@reduxjs/toolkit";
import authSlice from "./slice/authSlice";
import dialogSlice from "./slice/dialogSlice";
import userSlice from "./slice/userSlice";
import bannerSlice from "./slice/bannerSlice";
import categorySlice from "./slice/categorySlice";
import attributesSlice from "./slice/attributesSlice";
import productSlice from "./slice/productSlice";
import budgetSlice from "./slice/budgetSlice";
import orderSlice from "./slice/orderSlice";
import policySlice from "./slice/policySlice";
import paymentSlice from "./slice/paymentSlice";
import dashboardSlice from "./slice/dashboardSlice";
import settingSlice from "./slice/settingSlice";
import productCareSlice from "./slice/productCareSlice";

// Enable Redux DevTools Extension

const store = configureStore({
  reducer: {
    auth: authSlice,
    dialogue: dialogSlice,
    user: userSlice,
    banner: bannerSlice,
    category: categorySlice,
    attributes: attributesSlice,
    product: productSlice,
    budget: budgetSlice,
    order: orderSlice,
    policy: policySlice,
    productCare: productCareSlice,
    payment: paymentSlice,
    dashboard: dashboardSlice,
    setting: settingSlice,
  },
});

export default store;
