import React, { useEffect } from 'react';
import Title from '../../extra/Title';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { closeDialog } from '../../../redux/slice/dialogSlice';
import Saree from "../../../assets/images/admin.jpg"
import { paymentOrder } from '../../../redux/slice/orderSlice';
import { baseURL } from '../../util/config';

const PaymentDetails = () => {

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { dialogueData } = useSelector(
    (state) => state.dialogue
  );

  // normal Get
  useEffect(() => {
    dispatch(paymentOrder(dialogueData))
  }, []);

  const { order, isSkeleton } = useSelector((state) => state.order)

  return (

    <div className="dialog">

      <div className="w-100">
        <div className="row justify-content-center">
          <div className="col-xl-5 col-md-8 col-11">
            <div className="mainDiaogBox">
              <div className="row justify-content-between align-items-center formHead">
                <div className="col-8"></div>
                <div className="col-4">
                  <div
                    className="closeButton"
                    onClick={() => {
                      dispatch(closeDialog());
                    }}
                  >
                    <i className="ri-close-line"></i>
                  </div>
                </div>
              </div>
              <div className="orderDetails border-bottom-gray2-1  p10-bottom">
                <div className="row">
                  <div className="col-md-6 col-12">
                    <div className="orderAdress fs-lg-18 fs-md-16 fs-14">
                      <h3 className='fs-lg-24 fs-md-20 fs-18'>Delivery Address</h3>
                      <div className='fw-600'>{order[0]?.address?.fullName}  <span className='bg-gray text-light p3 fs-lg-16 fs-md-14 fs-12 m5-left'>{order[0]?.address?.type}</span></div>
                      <div className='text-darkGray'>{order[0]?.address?.details}</div>
                      <div className='text-success m3-top'>+91 {order[0]?.address?.phone}</div>

                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                {
                  !isSkeleton && (
                    order?.length > 0 ? (
                      <>
                        {
                          order?.map((res) => {
                            return (
                              <>
                                <div className="col-12 m30-top">
                                  <div className="orderText d-flex fs-lg-18 fs-md-16 fs-14">
                                    <div className="orderImage m20-right hw-lg-100 hw-80">
                                    {res?.productId?.productImage &&  <img src={baseURL + res?.productId?.productImage[0] || []} alt="Order Image" width={`100%`} />}
                                     
                                    </div>
                                    <div>
                                      <div className="orderId">
                                        <span className='fw-600'>Order ID :</span> <span className='text-darkGray'>{res?.orderId}</span>
                                      </div>
                                      <div className="orderTitle m10-top fw-600">{res?.productId?.title}</div>
                                      <div className="orderQut m5-top">
                                        <span>Quantity :</span> <span>{res?.productCount}</span>
                                      </div>
                                      <h4 className="orderPrice m5-top fs-lg-24 fs-md-20 fs-18">₹{res?.productId?.price}</h4>
                                    </div>
                                  </div>
                                </div>
                              </>
                            )
                          })
                        }
                      </>
                    ) : (
                      <h1> No Data </h1>
                    )

                  )

                }

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PaymentDetails;

