import React, { useEffect, useState } from "react";
import Title from "../../extra/Title";
import Table from "../../extra/Table";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "../../extra/Pagination";
import Searching from "../../extra/Searching";
import Button from "../../extra/Button";
import { closeDialog, openDialog } from "../../../redux/slice/dialogSlice";
import { warning } from "../../util/Alert";
import { useLocation, useNavigate } from "react-router-dom";
import {
  deleteProduct,
  deleteProductColor,
  productGet,
  updateMultiProduct,
} from "../../../redux/slice/productSlice";
import ToggleSwitch from "../../extra/ToggleSwitch";
import { orderAll, updateOrderStatus } from "../../../redux/slice/orderSlice";
import OrderShow from "./OrderShow";
import { baseURL } from "../../util/config";

const OrderOutOfDeliverd = () => {
  const dispatch = useDispatch();

  const { dialogue, dialogueType } = useSelector((state) => state.dialogue);

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const [data, setData] = useState([]);

  // const { product, productCount } = useSelector((state) => state.product)
  const { order, orderTotal } = useSelector((state) => state.order);

  const [search, setSearch] = useState("");
  const handleFilterData = (filteredData) => {
    if (typeof filteredData === "string") {
      setSearch(filteredData);
    } else {
      setData(filteredData);
    }
  };

  const [sortField, setSortField] = useState("");
  const [sortOrder, setSortOrder] = useState(0);
  const handleChildValue = (value) => {
    setSortOrder(sortOrder === 0 ? 1 : 0);
    setSortField(value);
  };

  const payload = {
    page,
    limit: rowsPerPage,
    search,
    status: 3,
    sortField,
    sortOrder,
  };
  // Server Get
  useEffect(() => {
    dispatch(orderAll({ ...payload, command: false }));
  }, [page, rowsPerPage, search, sortField, sortOrder]);

  // normal Get
  useEffect(() => {
    dispatch(orderAll({ ...payload, command: true }));
  }, []);

  useEffect(() => {
    setData(order);
  }, [order]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event, 10));
    setPage(0);
  };

  const handleChangeStatus = (orderId, status) => {
    dispatch(updateOrderStatus({ orderId, status }));
  };

  const orderTable = [
    {
      Header: "No",
      body: "No",
      Cell: ({ index }) => (
        <span>{page * rowsPerPage + parseInt(index) + 1}</span>
      )
    },
    {
      Header: "Product Details",
      body: "productImage",
      Cell: ({ row }) => (
        <span className='d-flex align-items-center'>
          <div className="userProfile m10-right" style={{ height: "100%", width: "80px", overflow: "hidden" }}>
            {row?.productImage && <img src={baseURL + row?.productImage[0] || []} alt="image" height={`100%`} />}
          </div>
          <span>
            <span className='space-wrap text-overflow-2 fw-600'>{row.title}</span>
            <div className='text-prime'>#{row.productCode}</div>
            <div className='fs-12'>QUT : {row.productCount}</div>
            <div>
              <span className='fw-500'>₹{row.price}</span>
              <span className='text-gray m5-left'><del>₹{row.oldPrice}</del></span>
            </div>
            <div className={`text-light d-inline-block p1-y p5-x fs-12 m5-top ${row?.statusLabels == 1 ? "bg-links" : row?.statusLabels == 2 ? "bg-blue" : row?.statusLabels == 3 ? "bg-success" : row?.statusLabels == 4 ? "bg-success" : row?.statusLabels == 5 ? "bg-danger" : row?.statusLabels == 6 && "bg-danger"}`}>

              {row?.statusLabels == 1 ? "Pendding" : row?.statusLabels == 2 ? "Confirm" : row?.statusLabels == 3 ? "Out of Deliverd" : row?.statusLabels == 4 ? "Deliverd" : row?.statusLabels == 5 ? "Cancellation Request" : row?.statusLabels == 6 && "Canceled"}

            </div>
          </span>
        </span>
      ),
    },
    { Header: "Order Id", body: "orderId", sorting: { type: "client" } },
    {
      Header: "Customer Name Details",
      Cell: ({ row }) => (
        <span>
          <h6>{row.fullName}</h6>
          <p className='text-wrap'>{row.details}</p>
          <p className='text-uppercase text-prime'>{row.city} {row.state} - {row.pincode}</p>
          <p className='text-wrap'>{row.phone}</p>
        </span>
      ),
      tdClass: "text-wrap",
      width: "400px"
    },
    {
      Header: "Order Treack",
      Cell: ({ row }) => (
        <span>
          {
            row?.awb != "" ? (
              <span><a className='trackingLink text-links fw-600' href={`https://shiprocket.co/tracking/${row?.awb}`} target='_blank'>{row.awb}</a></span>
            ) : ("-")
          }
        </span>
      ),
    },
    {
      Header: "Action",
      Cell: ({ row }) => (
        <span>
          <button className='bg-primary m15-right text-light p10-x p7-y fs-14 position-relative' onClick={() => dispatch(openDialog({ type: "orderInfo", data: row }))}>
            <i class="ri-information-line"></i>
          </button>
        </span>
      ),
    },
    { Header: "Payment Order Id", body: "paymentOrderId", sorting: { type: "client" } },
    { Header: "Payment  Id", body: "paymentId", sorting: { type: "client" } },


  ];

  const navigation = useNavigate();

  return (
    <div>
      <Title name={"Order"} />

      <div className="bg-light p15">
        <div className="row justify-content-between align-items-center">
          <div className="col-2 m0 fw-bold">Out of Delivery</div>
          <div className="col-md-5 col-smm-6 col-7 m0 ">
            <Searching
              type={`server`}
              data={order}
              setData={setData}
              column={orderTable}
              serverSearching={handleFilterData}
            />
          </div>
        </div>
      </div>
      <Table
        data={data}
        mapData={orderTable}
        serverPerPage={rowsPerPage}
        Page={page}
        onChildValue={handleChildValue}
      />
      <Pagination
        type={"server"}
        serverPage={page}
        setServerPage={setPage}
        serverPerPage={rowsPerPage}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        totalData={orderTotal}
      />

      {dialogue && dialogueType === "orderInfo" && <OrderShow />}
    </div>
  );
};

export default OrderOutOfDeliverd;
