import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiInstance } from "../../api/axiosApi";

const initialState = {
  policy: [],
  isLoading: false,
  isSkeleton: false,
};
export const policyGet = createAsyncThunk("policy/show", async () => {
  return apiInstance.get("policy/show");
});
export const policyAdd = createAsyncThunk("policy/create", async (payload) => {
  return apiInstance.post("policy/create", payload);
});
export const policyUpdate = createAsyncThunk("policy/update", async (payload) => {
  return apiInstance.patch(`policy/update?policyId=${payload.policyId}`, payload.addPolicy);
});
export const policyDelete = createAsyncThunk("policy/delete", async (id) => {
  return apiInstance.delete(`policy/delete?policyId=${id}`);
});

const policySlice = createSlice({
  name: "policySlice",
  initialState,
  reducers: {

  },
  extraReducers: (builder) => {
    // policyGet
    builder.addCase(policyGet.pending, (state, action) => {
      state.isSkeleton = true;
    });
    builder.addCase(policyGet.fulfilled, (state, action) => {
      state.policy = action.payload.policy;
      state.isSkeleton = false;

    });
    builder.addCase(policyGet.rejected, (state, action) => {
      state.isSkeleton = false;
    });

    // policyCreate
    builder.addCase(policyAdd.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(policyAdd.fulfilled, (state, action) => {
      state.policy.unshift(action.payload.policy);
      state.isLoading = false;
    });
    builder.addCase(policyAdd.rejected, (state, action) => {
      state.isLoading = false;
    });

    // policyUpdate
    builder.addCase(policyUpdate.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(policyUpdate.fulfilled, (state, action) => {
      const policyIdx = state.policy.findIndex((policy) => policy._id === action.payload.policy._id);
      if (policyIdx !== -1) {
        state.policy[policyIdx] = { ...state.policy[policyIdx], ...action.payload.policy };
      }
      state.isLoading = false;
    });
    builder.addCase(policyUpdate.rejected, (state, action) => {
      state.isLoading = false;
    });

    // policyDelete
    builder.addCase(policyDelete.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(policyDelete.fulfilled, (state, action) => {
      state.policy = state.policy.filter((policy) => policy._id !== action.meta.arg);
      state.isLoading = false;
    });
    builder.addCase(policyDelete.rejected, (state, action) => {
      state.isLoading = false;
    });

  },
});
export default policySlice.reducer;

