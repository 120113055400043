import React, { useEffect, useState } from "react";
import Title from "../../extra/Title";
import { useDispatch, useSelector } from "react-redux";
import {
  editData,
  generateNum,
  objectToFormData,
  submitData,
} from "../../util/fuction";
import { categoryGet } from "../../../redux/slice/categorySlice";
import { attributesGet } from "../../../redux/slice/attributesSlice";
import Input, { Image, MultiSelect, Select } from "../../extra/Input";
import Button from "../../extra/Button";
import {
  editProductColor,
  productAdd,
} from "../../../redux/slice/productSlice";
import { useLocation, useNavigate } from "react-router-dom";
import { closeDialog } from "../../../redux/slice/dialogSlice";
import { setToast } from "../../extra/toast";

const ProductColorDialogue = () => {
  const dispatch = useDispatch();

  const { dialogueData } = useSelector((state) => state.dialogue);

  const { attributes } = useSelector((state) => state.attributes);
  useEffect(() => {
    dispatch(attributesGet({ command: false }));
  }, []);

  useEffect(() => {
    if (dialogueData) {
      editData(dialogueData);
      setSizes(dialogueData.size);
      setTotalStock(dialogueData.stock);
    }
  }, [dialogueData]);

  const [sizes, setSizes] = useState([]);
  const [totalStock, setTotalStock] = useState("");
  const [selectSizes, setSelectSizes] = useState({
    sizeName: "",
    sizeStock: "",
  });

  const handleChangeValue = (value, names) => {
    setSelectSizes({ ...selectSizes, [names]: value });
  };

  const handleRemoveSizeData = (removeIndex) => {
    if (removeIndex < 0) {
      setToast("error", "Invalid index");
      return;
    }

    const removeStock = sizes[removeIndex].sizeStock;
    const finalStock = Number(totalStock) - Number(removeStock || totalStock);

    setTotalStock(finalStock <= 0 ? "" : finalStock);
    setSizes(sizes.filter((removeData, i) => removeIndex != i));
  };

  const handleMoveSizeData = (storeId) => {
    if (!selectSizes.sizeName || !selectSizes.sizeStock) {
      setToast("error", "Enter Size or Stock");
    } else {
      setTotalStock(Number(totalStock) + Number(selectSizes.sizeStock));
      setSizes([...sizes, selectSizes]);

      // Reset selectSizes after moving data
      setSelectSizes({ sizeName: "", sizeStock: "" });
    }
  };

  const handleSubmit = async (e) => {
    const addProduct = submitData(e);
    console.log("addProduct", addProduct);

    if (addProduct) {
      // const formData = new FormData();
      // for (let i = 0; i < addProduct.productImage.length; i++) {
      //   formData.append("productImage", addProduct.productImage[i]);
      // }

      const customObj = {
        ...addProduct,
        size: sizes,
      };
      console.log("customObj", customObj);
      const formData = objectToFormData(customObj, "single");
      const payload = { formData, productId: dialogueData._id };

      try {
        if (formData) {
          let response = await dispatch(editProductColor(payload)).unwrap();
          console.log(response.status, "response.data.status");
          if (response.status) {
            dispatch(closeDialog());
          } else {
            alert(response.message);
          }
        }
      } catch (err) {
        console.log("err", err);
        alert(err.message);
      }
    }
  };

  const attributeNames = ["Color", "Size"];
  const [colorData, sizeData] = attributeNames.map((attrName) =>
    attributes.find((obj) => obj["attrName"] === attrName)
  );

  return (
    <div className="dialog">
      <div className="w-100">
        <div className="row justify-content-center">
          <div className="col-xl-7 col-11 m0">
            <div className="mainDiaogBox">
              <div className="row justify-content-between align-items-center formHead">
                <div className="col-8">
                  <h2 className="text-second m0">Product Dialog</h2>
                </div>
                <div className="col-4">
                  <div
                    className="closeButton"
                    onClick={() => {
                      dispatch(closeDialog());
                    }}
                  >
                    <i className="ri-close-line"></i>
                  </div>
                </div>
              </div>
              <div className="mainAddProduct">
                <form
                  onSubmit={handleSubmit}
                  id="productColorForm"
                  className="position-relative p10-x"
                >
                  <div className="mainMultiSelector" id="mainMultiSelector">
                    <div className="row align-items-start formBody">
                      <div className="col-6">
                        <div className="row ">
                          <div className="col-12">
                            <Input
                              className={`p0`}
                              id={`color`}
                              name={`color`}
                              label={`Color`}
                              type={`color`}
                              placeholder={`Select Color`}
                              errorMessage={`Select Color`}
                            />
                          </div>
                          <div className="col-6">
                            <Input
                              id={`stock`}
                              name={`stock`}
                              label={`Stock`}
                              type={`text`}
                              placeholder={`Select Stock`}
                              errorMessage={`Select Stock`}
                              value={totalStock}
                              readOnly={true}
                            />
                          </div>
                          <div className="col-6">
                            <Input
                              type={`text`}
                              id={`sku`}
                              name={`sku`}
                              label={`SKU Id`}
                              placeholder={`SKU Id`}
                              errorMessage={`Enter SKU Id`}
                            />
                          </div>
                          <div className="col-6">
                            <Select
                              option={colorData?.details}
                              className={`inputSelect`}
                              id={`colorB`}
                              name={`colorB`}
                              label={`Blouse Color`}
                              placeholder={`Select Blouse Color`}
                              errorMessage={`Select Blouse Color`}
                              title={`color`}
                            />
                          </div>
                          <div className="col-6">
                            <Select
                              option={sizeData?.details}
                              className={`inputSelect`}
                              id={`sizeB`}
                              name={`sizeB`}
                              label={`Blouse Size`}
                              placeholder={`Select Blouse Size`}
                              errorMessage={`Select Blouse Size`}
                            />
                          </div>
                          <div className="col-12">
                            <Image
                              id={`productImage`}
                              name={`productImage`}
                              label={`Product Image`}
                              errorMessage={`Enter Product Image`}
                              multiple={true}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="row ">
                          <div className="row align-items-center">
                            <div className="col-xsm-5 col-12">
                              <Select
                                option={sizeData?.details}
                                className={`inputSelect`}
                                id={`sizeName`}
                                name={`sizeName`}
                                label={`Size`}
                                placeholder={`Select Size`}
                                errorMessage={`Select Size`}
                                onChange={(e) =>
                                  handleChangeValue(e, "sizeName")
                                }
                                dataValue={selectSizes.sizeName}
                                ignore={true}
                              />
                            </div>
                            <div className="col-xsm-5 col-10">
                              <Input
                                type={`number`}
                                id={`sizeStock`}
                                name={`sizeStock`}
                                label={`Stock`}
                                placeholder={`Stock`}
                                errorMessage={`Enter Stock`}
                                onChange={(e) =>
                                  handleChangeValue(e.target.value, "sizeStock")
                                }
                                ignore={true}
                                value={selectSizes.sizeStock}
                              />
                            </div>
                            <div className="col-2 m-0">
                              <div className="primeButton">
                                <button
                                  type="button"
                                  onClick={() => handleMoveSizeData()}
                                >
                                  +
                                </button>
                              </div>
                            </div>
                            <div className="col-12">
                              <div className="allSizes d-inline-flex flex-wrap w-100 fs-xl-16 fs-md-14 fs-12">
                                {sizes?.map((res, i) => (
                                  <div className="showSizes">
                                    <div
                                      className="removeSize"
                                      onClick={() => handleRemoveSizeData(i)}
                                    >
                                      <i class="ri-close-line"></i>
                                    </div>
                                    <span className="text-success">
                                      {res.sizeName} -{" "}
                                    </span>
                                    <span className="text-blue">
                                      {" "}
                                      {res.sizeStock} Pieces
                                    </span>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row formFooter">
                    <div className="col-6 text-center m0">
                      <Button
                        className={`bg-gray text-light w-100`}
                        text={`Cancel`}
                        type={`button`}
                      />
                    </div>
                    <div className="col-6 text-center m0">
                      <Button
                        type={`submit`}
                        className={`bg-second text-light w-100`}
                        text={`Submit`}
                      />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductColorDialogue;
