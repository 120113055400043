import React, { useEffect } from "react";
import Title from "../../extra/Title";
import Input from "../../extra/Input";
import { closeDialog } from "../../../redux/slice/dialogSlice";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../extra/Button";
import { settingGet, settingUpdate } from "../../../redux/slice/settingSlice";
import { editData, objectToFormData, submitData } from "../../util/fuction";

const ImageSetting = () => {
  const dispatch = useDispatch();
  const { setting } = useSelector((state) => state.setting);

  useEffect(() => {
    dispatch(settingGet());
  }, []);
  useEffect(() => {
    if (setting) editData(setting);
  }, [setting]);

  const handleSubmit = async (e) => {
    const updateSetting = submitData(e);
    if (updateSetting) {
      console.log("updateSetting", updateSetting);
      const formData = objectToFormData(updateSetting);
      if (formData) {
        await dispatch(settingUpdate(formData));
      }
    }
  };
  return (
    <>
      <Title name={`Image Setting`} />

      <div className="startSetting">
        <form onSubmit={handleSubmit} id="imageSetting">
          <div
            className="row align-items-start formBody bg-light p20"
            style={{ boxShadow: "0 0 12px #0f223a1f" }}
          >
            <div className="col-md-6 col-12">
              <Input
                type={`file`}
                id={`logo`}
                name={`logo`}
                label={`Logo`}
                errorMessage={`Enter Logo`}
                imageClass={`width-sm-200 height-sm-100 width-100 height-50 object-contain dropShadow`}
              />
            </div>
            <div className="col-md-6 col-12">
              <Input
                type={`file`}
                id={`homeBanner`}
                name={`homeBanner`}
                label={`Home Banner`}
                errorMessage={`Enter Home Banner`}
                imageClass={`width-sm-200 height-sm-100 width-100 height-50`}
              />
            </div>
            <div className="col-md-6 col-12">
              <Input
                type={`file`}
                id={`megaBanner`}
                name={`megaBanner`}
                label={`Mega Banner`}
                errorMessage={`Enter Mega Banner`}
                imageClass={`width-sm-200 height-sm-100 width-100 height-50`}
              />
            </div>
            <div className="col-md-6 col-12">
              <Input
                type={`file`}
                id={`aboutBanner`}
                name={`aboutBanner`}
                label={`About Banner`}
                errorMessage={`Enter About Banner`}
                imageClass={`width-sm-200 height-sm-100 width-100 height-50`}
              />
            </div>
          </div>
          <div className="row m20-top formFooter">
            <div className="col-12 text-center m0">
              <Button
                type={`submit`}
                className={`bg-second text-light m10-left`}
                text={`Update`}
              />
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default ImageSetting;
