import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiInstance } from "../../api/axiosApi";

const initialState = {
  budget: [],
  isLoading: false,
  isSkeleton: false,
};
export const budgetGet = createAsyncThunk("budget/show", async () => {
  return apiInstance.get("budget/show");
});
export const budgetAdd = createAsyncThunk("budget/create", async (payload) => {
  return apiInstance.post("budget/create", payload);
});
export const budgetUpdate = createAsyncThunk("budget/update", async (payload) => {
  return apiInstance.patch(`budget/update?budgetId=${payload.budgetId}`, payload.formData);
});
export const budgetDelete = createAsyncThunk("budget/delete", async (id) => {
  return apiInstance.delete(`budget/delete?budgetId=${id}`);
});

const budgetSlice = createSlice({
  name: "budgetSlice",
  initialState,
  reducers: {

  },
  extraReducers: (builder) => {
    // budgetGet
    builder.addCase(budgetGet.pending, (state, action) => {
      state.isSkeleton = true;
    });
    builder.addCase(budgetGet.fulfilled, (state, action) => {
      state.budget = action.payload.budget;
      state.isSkeleton = false;

    });
    builder.addCase(budgetGet.rejected, (state, action) => {
      state.isSkeleton = false;
    });

    // budgetCreate
    builder.addCase(budgetAdd.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(budgetAdd.fulfilled, (state, action) => {
      state.budget.unshift(action.payload.budget);
      state.isLoading = false;
    });
    builder.addCase(budgetAdd.rejected, (state, action) => {
      state.isLoading = false;
    });

    // budgetUpdate
    builder.addCase(budgetUpdate.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(budgetUpdate.fulfilled, (state, action) => {
      const budgetIdx = state.budget.findIndex((budget) => budget._id === action.payload.budget._id);
      if (budgetIdx !== -1) {
        state.budget[budgetIdx] = { ...state.budget[budgetIdx], ...action.payload.budget };
      }
      state.isLoading = false;
    });
    builder.addCase(budgetUpdate.rejected, (state, action) => {
      state.isLoading = false;
    });

    // budgetDelete
    builder.addCase(budgetDelete.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(budgetDelete.fulfilled, (state, action) => {
      state.budget = state.budget.filter((budget) => budget._id !== action.meta.arg);
      state.isLoading = false;
    });
    builder.addCase(budgetDelete.rejected, (state, action) => {
      state.isLoading = false;
    });

  },
});
export default budgetSlice.reducer;

