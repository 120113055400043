import React, { useEffect, useState } from 'react';
import Title from '../../extra/Title';
import Saree from "../../../assets/images/admin.jpg"
import { useDispatch, useSelector } from 'react-redux';
import { updateAdminData, updateAdminPassword, updateImage } from '../../../redux/slice/authSlice';
import { baseURL } from '../../util/config';
import Input from '../../extra/Input';
import { editData, submitData } from '../../util/fuction';
import Button from '../../extra/Button';

const AdminProfile = () => {

  const { admin } = useSelector((state) => state.auth);
  const sessionUserId = admin || sessionStorage.getItem(JSON.parse("token"));

  const [image, setImage] = useState([]);
  const [imagePath, setImagePath] = useState("");

  useEffect(() => {
    if (sessionUserId) {
      editData(sessionUserId)
    }
  }, [sessionUserId]);

  const handleUploadImage = (e) => {
    setImage(e.target.files[0]);
    setImagePath(URL.createObjectURL(e.target.files[0]));
  };

  const dispatch = useDispatch()


  const handleImage = () => {
    const formData = new FormData();
    formData.append("image", image);
    dispatch(updateImage(formData))
  };

  const handleSubmit = async (e) => {
    const addBanner = submitData(e);
    if (addBanner) {
      await dispatch(updateAdminData(addBanner)).unwrap();
    }
  };
  const handleSubmitPassword = async (e) => {
    const addBanner = submitData(e);
    if (addBanner) {
      await dispatch(updateAdminPassword(addBanner)).unwrap();
    }
  };


  return (
    <div>
      <Title name={`Admin Profile`} />
      <div className="mainAdminProfile">
        <div className="row m40-sm-top m20-top">
          <div className="col-lg-4 col-sm-6 col-12 m-auto">
            <div className="adminDetails bg-light p20 text-center">
              <div className="adminProfileImage hw-lg-120 hw-sm-100 hw-80  m-auto  position-relative">
                <div className="entryImage hw-lg-30 hw-25  bg-second overflow-hidden ">
                  <i class="ri-edit-line text-light  position-relative"></i>
                  <input
                    id="file-input"
                    type="file"
                    accept="image/*"
                    className="adminImage"
                    onChange={(e) => handleUploadImage(e)}
                  />
                </div>
                <div className="adminImageHeader hw-lg-120 hw-sm-100 hw-80 m-auto overflow-hidden">
                  <img src={imagePath ? imagePath : baseURL + sessionUserId.image} alt="admin profile" height={`100%`} width={`100%`} />
                </div>
              </div>
              <div className="adminText m20-top">
                <h3 className='m0 fs-lg-24 fs-sm-20 fs-16'>{sessionUserId.name}</h3>
                <p className='fs-lg-18 fs-sm-14 fs-14'>Admin</p>
                <div className="primeButton m20-top fs-lg-18 fs-sm-14 fs-14">
                  <button onClick={handleImage}>Update Image</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row m40-sm-top m20-top">
          <div className="col-sm-6 col-12">
            <div className="adminDetails bg-light p20">
              <form onSubmit={handleSubmit} id="adminDetailsForm">
                <div className="row align-items-start formBody">
                  <div className="col-12">
                    <Input
                      type={`text`}
                      id={`name`}
                      name={`name`}
                      label={`Name`}
                      placeholder={`Name`}
                      errorMessage={`Enter Name`}
                    />
                  </div>
                  <div className="col-12">
                    <Input
                      type={`text`}
                      id={`email`}
                      name={`email`}
                      label={`Email`}
                      placeholder={`Email`}
                      errorMessage={`Enter Email`}
                    />
                  </div>
                </div>
                <div className="row formFooter">
                  <div className="col-12 text-end m0">
                    <Button
                      type={`submit`}
                      className={`bg-second text-light`}
                      text={`Update`}
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="col-sm-6 col-12">
            <div className="adminDetails bg-light p20">
              <form onSubmit={handleSubmitPassword} id="adminPasswordForm">
                <div className="row align-items-start formBody">
                  <div className="col-12">
                    <Input
                      type={`text`}
                      id={`oldPassword`}
                      name={`oldPassword`}
                      label={`Old Password`}
                      placeholder={`Old Password`}
                      errorMessage={`Enter Old Password`}
                    />
                  </div>
                  <div className="col-12">
                    <Input
                      type={`text`}
                      id={`password`}
                      name={`password`}
                      label={`Password`}
                      placeholder={`Password`}
                      errorMessage={`Enter Password`}
                    />
                  </div>
                </div>
                <div className="row formFooter">
                  <div className="col-12 text-end m0">
                    <Button
                      type={`submit`}
                      className={`bg-second text-light`}
                      text={`Update`}
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdminProfile;
