import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { SetDevKey, apiInstance, setToken } from "../../api/axiosApi";
import jwt_decode from "jwt-decode";
import { key } from "../../component/util/config";
import { setToast } from "../../component/extra/toast";


const initialState = {
  admin: {},
  isAuth: false,
  isLoading: false
};
export const login = createAsyncThunk("admin/login", async (payload) => {
  return apiInstance.post("admin/login", payload);
});

export const updateImage = createAsyncThunk("admin/updateImage", async (payload) => {
  return apiInstance.put("admin/updateImage", payload);
});

export const updateAdminData = createAsyncThunk("admin/update", async (payload) => {
  return apiInstance.patch("admin/update", payload);
});
export const updateAdminPassword = createAsyncThunk("admin/updatePassword", async (payload) => {
  return apiInstance.patch("admin/updatePassword", payload);
});


const authSlice = createSlice({
  name: "authSlice",
  initialState,
  reducers: {
    setOldAdmin(state, action) {
      let token_ = JSON.parse(action.payload.token);
      state.admin = token_;
      state.isAuth = true;
      setToken(action.payload.tokenSil);
      SetDevKey(key);
    },
    logout(state, action) {
      sessionStorage.removeItem("token");
      sessionStorage.removeItem("tokenSil");
      sessionStorage.removeItem("key");
      sessionStorage.removeItem("isAuth");
      state.admin = {};
      state.isAuth = false;

    }
  },
  extraReducers: (builder) => {
    // Admin Login
    builder.addCase(login.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(login.fulfilled, (state, action) => {
      let token_ = jwt_decode(action.payload.token);
      state.admin = token_;
      state.isAuth = true;
      state.isLoading = false;

      SetDevKey(key);
      setToken(action.payload.token);
      sessionStorage.setItem("tokenSil", action.payload.token ? action.payload.token : undefined);
      sessionStorage.setItem("token", token_ ? JSON.stringify(token_) : undefined);
      sessionStorage.setItem("key", key ? key : undefined);
      sessionStorage.setItem("isAuth", true);
    });
    builder.addCase(login.rejected, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(updateImage.fulfilled, (state, action) => {
      if (action?.payload?.status) {
        let token_ = jwt_decode(action.payload.token);
        state.admin = token_;
        sessionStorage.setItem("token", token_ ? JSON.stringify(token_) : undefined);

        setToken(action.payload.token);
        sessionStorage.setItem("tokenSil", action.payload.token ? action.payload.token : undefined);

        setToast("success", action.payload.message)
      } else {
        setToast("error", action?.payload?.message)
      }
    });

    builder.addCase(updateAdminData.fulfilled, (state, action) => {
      if (action?.payload?.status) {
        let token_ = jwt_decode(action.payload.token);
        state.admin = token_;
        sessionStorage.setItem("token", token_ ? JSON.stringify(token_) : undefined);

        setToken(action.payload.token);
        sessionStorage.setItem("tokenSil", action.payload.token ? action.payload.token : undefined);

        setToast("success", action.payload.message)
      } else {
        setToast("error", action?.payload?.message)
      }
    });

    builder.addCase(updateAdminPassword.fulfilled, (state, action) => {
      if (action?.payload?.status) {
        let token_ = jwt_decode(action.payload.token);
        state.admin = token_;
        sessionStorage.setItem("token", token_ ? JSON.stringify(token_) : undefined);

        setToken(action.payload.token);
        sessionStorage.setItem("tokenSil", action.payload.token ? action.payload.token : undefined);

        setToast("success", action.payload.message)
      } else {
        setToast("error", action?.payload?.message)
      }
    });

  },
});
export default authSlice.reducer;
export const { setOldAdmin, logout } = authSlice.actions

