import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiInstance } from "../../api/axiosApi";

const initialState = {
  dashboard: [],
  isLoading: false,
  isSkeleton: false,
};
export const dashboardGet = createAsyncThunk("dashboard/show", async () => {
  return apiInstance.get("dashboard/show");
});
const dashboardSlice = createSlice({
  name: "dashboardSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // dashboardGet
    builder.addCase(dashboardGet.pending, (state, action) => {
      state.isSkeleton = true;
    });
    builder.addCase(dashboardGet.fulfilled, (state, action) => {
      state.dashboard = action?.payload?.dashboard;
      state.isSkeleton = false;
    });
    builder.addCase(dashboardGet.rejected, (state, action) => {
      state.isSkeleton = false;
    });
  },
});
export default dashboardSlice.reducer;
