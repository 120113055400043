import React, { useEffect, useState } from "react";
import Title from "../../extra/Title";
import Table from "../../extra/Table";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "../../extra/Pagination";
import Searching from "../../extra/Searching";
import { baseURL } from "../../util/config";
import { paymentGet } from "../../../redux/slice/paymentSlice";
import PaymentDetails from "./PaymentDetails";
import { openDialog } from "../../../redux/slice/dialogSlice";

const Payment = () => {
  const dispatch = useDispatch();

  const { dialogue, dialogueType } = useSelector((state) => state.dialogue);

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const [data, setData] = useState([]);

  const { payment, paymentTotal } = useSelector((state) => state.payment);

  const [search, setSearch] = useState("");
  const handleFilterData = (filteredData) => {
    if (typeof filteredData === "string") {
      setSearch(filteredData);
    } else {
      setData(filteredData);
    }
  };

  const payload = {
    page,
    limit: rowsPerPage,
    search,
  };
  // Server Get
  useEffect(() => {
    dispatch(paymentGet({ ...payload, command: false }));
  }, [page, rowsPerPage, search]);

  // normal Get
  useEffect(() => {
    dispatch(paymentGet({ ...payload, command: true }));
  }, []);

  useEffect(() => {
    setData(payment);
  }, [payment]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event, 10));
    setPage(0);
  };

  const paymentTable = [
    {
      Header: "No",
      body: "No",
      Cell: ({ index }) => (
        <span>{page * rowsPerPage + parseInt(index) + 1}</span>
      ),
    },
    {
      Header: "User Id",
      body: "customerId",
      Cell: ({ row }) => <span>#{row.customerId}</span>,
      sorting: { type: "client" },
    },
    {
      Header: "User Name",
      body: "name",
      Cell: ({ row }) => (
        <span className="d-flex align-items-center">
          <div
            className="userProfile rounded-5 m10-right hw-40"
            style={{ overflow: "hidden" }}
          >
            <img src={baseURL + row.profileImage} alt="image" height={`100%`} />
          </div>
          <span>{row?.name}</span>
        </span>
      ),
    },
    { Header: "Email", body: "email", sorting: { type: "client" } },
    { Header: "Mobile No", body: "mobileNo", sorting: { type: "client" } },
    {
      Header: "Payment Order Id",
      body: "paymentOrderId",
      sorting: { type: "client" },
    },
    { Header: "Payment Id", body: "paymentId", sorting: { type: "client" } },
    {
      Header: "Amount",
      body: "amount",
      Cell: ({ row }) => <span>₹{row.amount}</span>,
      sorting: { type: "client" },
    },
    {
      Header: "Verification",
      Cell: ({ row }) => (
        <span>
          {row.paymentStatus === 1 ? (
            <button
              className="bg-primary m15-right text-light p10-x p7-y fs-14 position-relative"
              onClick={() =>
                dispatch(
                  openDialog({
                    type: "orderInfo",
                    data: {
                      paymentOrderId: row?.paymentOrderId,
                      paymentId: row?.paymentId,
                    },
                  })
                )
              }
            >
              <i class="ri-information-line"></i>
            </button>
          ) : (
            <button className="m15-right text-danger p10-x p7-y fs-14 position-relative">
              <i class="ri-close-circle-line"></i>
            </button>
          )}
        </span>
      ),
    },
  ];

  return (
    <div>
      <Title name={"Payment"} />

      <div className="bg-light p15">
        <div className="row justify-content-between align-items-center">
          <div className="col-2 m0"></div>
          <div className="col-md-5 col-smm-6 col-7 m0 ">
            <Searching
              type={`server`}
              data={payment}
              setData={setData}
              column={paymentTable}
              serverSearching={handleFilterData}
            />
          </div>
        </div>
      </div>
      <Table
        data={data}
        mapData={paymentTable}
        serverPerPage={rowsPerPage}
        Page={page}
      />
      <Pagination
        type={"server"}
        serverPage={page}
        setServerPage={setPage}
        serverPerPage={rowsPerPage}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        totalData={paymentTotal}
      />

      {/* <PaymentDetails/> */}
      {dialogue && dialogueType === "orderInfo" && <PaymentDetails />}
    </div>
  );
};

export default Payment;
