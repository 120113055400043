import React, { useEffect, useState } from 'react';
import Title from '../../extra/Title';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { showCart, showWishlist, userOrder, userProfile } from '../../../redux/slice/userSlice';
import { baseURL } from '../../util/config';
import Table from '../../extra/Table';
import Pagination from '../../extra/Pagination';

const UserDetails = () => {
  const { userProfileShow, order } = useSelector((state) => state.user)
  const [path, setPath] = useState(1);


  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event, 10));
    setPage(0);
  };


  const dispatch = useDispatch()
  const location = useLocation()
  const profileUserId = location.state || localStorage.getItem("userProfileId")

  useEffect(() => {
    dispatch(userProfile(profileUserId))
    dispatch(userOrder(profileUserId))
  }, []);


  const productTable = [
    {
      Header: "No",
      body: "No",
      Cell: ({ index }) => (
        <span>{page * rowsPerPage + parseInt(index) + 1}</span>
      )
    },
    { Header: "Order Id", body: "orderId", sorting: { type: "client" } },
    { Header: "Product Code", body: "productCode", sorting: { type: "client" } },
    {
      Header: "Product Image",
      body: "productImage",
      Cell: ({ row }) => (
        <div className="userProfile" style={{ height: "80px", width: "80px", overflow: "hidden" }}>
          <img src={baseURL + row?.productId?.productImage[0]} alt="image" height={`100%`} />
        </div>
      ),
    },
    {
      Header: "Title",
      body: "title",
      Cell: ({ row }) => (
        <span>₹{row?.productId?.title}</span>
      ),
      sorting: { type: "client" },
      tdClass: "text-wrap",
      width: "400px"
    },
    {
      Header: "Count",
      body: "productCount",
      Cell: ({ row }) => (
        <span>{row.productCount}</span>
      ),
      sorting: { type: "client" }
    },
    {
      Header: "Status",
      body: "confirmStatus",
      Cell: ({ row }) => (
        <div className={`orderStatus fw-800 d-inline-block m10-top fs-lg-16 fs-xl-12 fs-10 p10-x p5-y text-light ${row?.confirmStatus == 1 ? " bg-warning" : row?.confirmStatus == 2 ? " bg-blue" : row?.confirmStatus == 3 ? " bg-success" : row?.confirmStatus == 4 ? " bg-danger" : row?.confirmStatus == 5 && "bg-third"
          }`}>
          {
            row?.confirmStatus == 1 ? "Pending" : row?.confirmStatus == 2 ? "Confirm" : row?.confirmStatus == 3 ? "Delivered" : row?.confirmStatus == 4 ? "Cancelled" : row?.confirmStatus == 5 && "Return"
          }

        </div>
      ),
      sorting: { type: "client" }
    },


  ];



  return (
    <div>
      <Title name={`User`} subName={`User Profile`} />

      <div className="mainUserProfile m40-top">
        <div className="row">
          <div className="col-12">
            <div className="profileNav fs-lg-20 fs-md-18 fs-16 d-flex align-items-center justify-content-between">
              <div className="profileInfo d-flex align-items-center">
                <div className="profileImage rounded-5 overflow-hidden hw-lg-50 hw-40 m20-md-right m10-right border-second-2">
                  <img src={baseURL + userProfileShow.profileImage} alt="" className='w-100' />
                </div>
                <div className="profileName">
                  <div className='fw-700 text-capitalize'>{userProfileShow?.name}</div>
                  <div className='text-success fs-md-18 fs-16'>#{userProfileShow?.customerId}</div>
                </div>
              </div>
              <ul className="mainProfileMenu d-flex">
                <li onClick={() => setPath(1)} className={path == 1 && "text-second"}>
                  <div className="navIcons fs-lg-20 fs-md-18 fs-16">
                    <span><i class="ri-settings-3-line fs-lg-24 fs-md-22 fs-20 m10-right"></i></span>
                    <span>User Details</span>
                  </div>
                </li>
                <li onClick={() => setPath(2)} className={path == 2 && "text-second"}>
                  <div className="navIcons fs-lg-20 fs-md-18 fs-16">
                    <span><i class="ri-box-1-line fs-lg-24 fs-md-22 fs-20 m10-right"></i></span>
                    <span>My Order</span>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>


        {
          path == 1 &&
          <div className="row m40-top">
            <div className="col-12">
              <div className="userProfileSide">
                <div className="row">
                  <div className="col-6 m20-bottom">
                    <div className="showMyDetails">
                      <div className='inputText m10-bottom'>Name</div>
                      <div className="inputField">{userProfileShow?.name}</div>
                    </div>
                  </div>
                  <div className="col-6 m20-bottom">
                    <div className="showMyDetails">
                      <div className='inputText m10-bottom'>Email</div>
                      <div className="inputField">{userProfileShow?.email}</div>
                    </div>
                  </div>
                  <div className="col-6 m20-bottom">
                    <div className="showMyDetails">
                      <div className='inputText m10-bottom'>Gender</div>
                      <div className="inputField">{userProfileShow?.gender}</div>
                    </div>
                  </div>
                  <div className="col-6 m20-bottom">
                    <div className="showMyDetails">
                      <div className='inputText m10-bottom'>Mobile No</div>
                      <div className="inputField">{userProfileShow?.mobileNo}</div>
                    </div>

                  </div>
                  <div className="col-12 m20-bottom">
                    <div className="showMyDetails">
                      <div className='inputText m10-bottom'>Address</div>
                      {
                        userProfileShow?.address?.map((res) => <div className="inputField m15-bottom text-capitalize">
                          <h5>{res?.fullName}</h5>
                          <p>{res?.details?.socName} , {res?.details?.city} - {res?.details?.pincode} </p>
                          <p>{res?.details?.state}, {res?.details?.country} - +91 {res?.phone}</p>
                        </div>)
                      }

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }


        {
          path == 2 &&
          <div className="row m40-top">
            <div className="col-12">
              <Table
                data={order}
                mapData={productTable}
                PerPage={rowsPerPage}
                Page={page}
              />
              <Pagination
                type={"client"}
                serverPage={page}
                setServerPage={setPage}
                serverPerPage={rowsPerPage}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                totalData={order.length}
              />
            </div>
          </div>
        }

      </div>

    </div>
  );
}

export default UserDetails;
