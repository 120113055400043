import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiInstance } from "../../api/axiosApi";

const initialState = {
  setting: {},
  isLoading: false,
  isSkeleton: false,
};
export const settingGet = createAsyncThunk("setting/show", async () => {
  return apiInstance.get("setting/show");
});
export const settingUpdate = createAsyncThunk("setting/update", async (payload) => {
  return apiInstance.patch(`setting/update`, payload);
});

const settingSlice = createSlice({
  name: "settingSlice",
  initialState,
  reducers: {

  },
  extraReducers: (builder) => {
    // settingGet
    builder.addCase(settingGet.pending, (state, action) => {
      state.isSkeleton = true;
    });
    builder.addCase(settingGet.fulfilled, (state, action) => {
      state.setting = action.payload.setting;
      state.isSkeleton = false;

    });
    builder.addCase(settingGet.rejected, (state, action) => {
      state.isSkeleton = false;
    });

    // settingUpdate
    builder.addCase(settingUpdate.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(settingUpdate.fulfilled, (state, action) => {
      state.setting = action.payload.setting
      state.isLoading = false;
    });
    builder.addCase(settingUpdate.rejected, (state, action) => {
      state.isLoading = false;
    });


  },
});
export default settingSlice.reducer;

