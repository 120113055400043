import React, { useEffect, useState } from 'react';
import Table from '../../extra/Table';
import Title from '../../extra/Title';
import Pagination from '../../extra/Pagination';
import Searching from '../../extra/Searching';
import UserAdd from './UserEdit';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../extra/Button';
import { openDialog } from '../../../redux/slice/dialogSlice';
import { userAll, userBlock } from '../../../redux/slice/userSlice';
import { baseURL } from '../../util/config';
import ToggleSwitch from '../../extra/ToggleSwitch';
import UserEdit from './UserEdit';
import { useNavigate } from 'react-router-dom';
const User = () => {

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { dialogue, dialogueType } = useSelector(
    (state) => state.dialogue
  );
  const [data, setData] = useState([]);

  const { user } = useSelector((state) => state.user)

  useEffect(() => {
    dispatch(userAll())
  }, []);

  useEffect(() => {
    setData(user)
  }, [user]);



  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event, 10));
    setPage(0);
  };


  const [search, setSearch] = useState("");
  const handleFilterData = (filteredData) => {
    if (typeof filteredData === "string") {
      setSearch(filteredData);
    } else {
      setData(filteredData);
    }
  };
  // const [field, setField] = useState("date");
  const [type, setType] = useState(0);
  const handleChildValue = (value) => {
    setType(type === 0 ? 1 : 0);
    // setField(value);
  };

  const handleMove = (id) => {
    localStorage.setItem("userProfileId",id)
    navigate("/admin/user/userDetails", { state: id })
  }

  const userTable = [
    {
      Header: "No",
      Cell: ({ index }) => (
        <span>{page * rowsPerPage + parseInt(index) + 1}</span>
      )
    },
    {
      Header: "Profile Image",
      body: "name",
      Cell: ({ row }) => (
        <div className='d-flex align-items-center'>
          <div className="userProfile hw-50 overflow-hidden rounded-5 m15-right">
            <img src={baseURL + row.profileImage} alt="profileImage" />
          </div>
          <div className='fs-sm-18 fs-16'>{row.name}</div>
        </div>
      ),
      sorting: { type: "client" }
    },
    { Header: "Customer Id", body: "customerId", sorting: { type: "client" } },
    { Header: "Email", body: "email", sorting: { type: "client" } },
    { Header: "Mobile No", body: "mobileNo", sorting: { type: "client" } },
    { Header: "Gender", body: "gender", sorting: { type: "client" } },
    {
      Header: "Block",
      body: "isBlock",
      Cell: ({ row }) => (
        <ToggleSwitch value={row.isBlock}
          onClick={() => dispatch(userBlock(row._id))}
    />
      ),
      sorting: { type: "client" }
    },
    {
      Header: "Action",
      Cell: ({ row }) => (
        <span>
          <button
            type='button'
            className='bg-second text-light m15-right p10-x p7-y fs-14'
            onClick={() => dispatch(openDialog({ type: "user", data: row }))}
          ><i class="ri-edit-2-line"></i></button>
          <button className='bg-primary m15-right text-light p10-x p7-y fs-14 position-relative'
            onClick={() => handleMove(row._id)}
          >
            <i class="ri-information-line"></i>
          </button>
        </span>
      ),
    }

  ];

  return (
    <div>
      <Title name={"User"} />

      <div className='bg-light p15'>
        <div className="row justify-content-between align-items-center">
          <div className="col-2 m0"></div>
          <div className="col-md-5 col-smm-6 col-7 m0 ">
            <Searching
              button={false}
              type={`client`}
              data={data}
              setData={setData}
              column={userTable}
              onFilterData={handleFilterData}
              serverSearching={handleFilterData}
              searchValue={search}
              setSearchValue={setSearch}
            />
          </div>
        </div>
      </div>
      <Table
        data={data}
        mapData={userTable}
        PerPage={rowsPerPage}
        Page={page}
        type={"client"}
        onChildValue={handleChildValue}
      />
      <Pagination
        type={"client"}
        serverPage={page}
        setServerPage={setPage}
        serverPerPage={rowsPerPage}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        totalData={data?.length}
      />


      {dialogue && dialogueType === "user" && (
        <UserEdit />
      )}
      {/* <UserAdd /> */}
    </div>
  );
}

export default User;
