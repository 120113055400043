import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiInstance } from "../../api/axiosApi";

const initialState = {
  payment: [],
  paymentTotal: 0,
  isLoading: false,
  isSkeleton: false,
};
export const paymentGet = createAsyncThunk("payment/getPaymentData", async (payload) => {
  return apiInstance.get(`payment/getPaymentData?page=${payload.page}&limit=${payload.limit}&search=${payload.search}`);
});


const paymentSlice = createSlice({
  name: "paymentSlice",
  initialState,
  reducers: {

  },
  extraReducers: (builder) => {
    // paymentGet
    builder.addCase(paymentGet.pending, (state, action) => {
      state.isSkeleton = action.meta.arg.command;
    });
    builder.addCase(paymentGet.fulfilled, (state, action) => {
      state.payment = action.payload.payment;
      state.paymentTotal = action.payload.paymentTotal;
      state.isSkeleton = false;

    });
    builder.addCase(paymentGet.rejected, (state, action) => {
      state.isSkeleton = false;
    });

  },
});
export default paymentSlice.reducer;

