import React, { useEffect } from 'react';
import Title from '../../extra/Title';
import Input, { Textarea } from '../../extra/Input';
import { closeDialog } from '../../../redux/slice/dialogSlice';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../extra/Button';
import { settingGet, settingUpdate } from '../../../redux/slice/settingSlice';
import { editData, objectToFormData, submitData } from '../../util/fuction';

const GeneralSetting = () => {
  const dispatch = useDispatch();
  const { setting } = useSelector((state) => state.setting);

  useEffect(() => {
    dispatch(settingGet())
  }, []);
  useEffect(() => {
    if (setting) editData(setting)
  }, [setting]);

  const handleSubmit = async (e) => {
    const updateSetting = submitData(e);
    if (updateSetting) {

      console.log("updateSetting", updateSetting);
      const formData = objectToFormData(updateSetting);
      if (formData) { await dispatch(settingUpdate(formData)); }
    }

  };
  return (
    <>

      <Title name={`General Setting`} />

      <div className="startSetting">
        <form onSubmit={handleSubmit} id="generalSetting">
          <div className="row align-items-start formBody bg-light p20" style={{ boxShadow: "0 0 12px #0f223a1f" }}>
            <div className="col-md-6 col-12">
              <Input
                type={`text`}
                id={`razorKey`}
                name={`razorKey`}
                label={`Razor Key`}
                placeholder={`Razor Key`}
                errorMessage={`Enter Razor Key`}
              />
            </div>
            <div className="col-md-6 col-12">
              <Input
                type={`text`}
                id={`razorApiSecret`}
                name={`razorApiSecret`}
                label={`razor Api Secret`}
                placeholder={`razor Api Secret`}
                errorMessage={`Enter razor Api Secret`}
              />
            </div>
            <div className="col-md-6 col-12">
              <Input
                type={`text`}
                id={`deliveryDay`}
                name={`deliveryDay`}
                label={`Delivery Day`}
                placeholder={`Delivery Day`}
                errorMessage={`Enter Delivery Day`}
              />
            </div>
            <div className="col-md-6 col-12">
              <Input
                type={`text`}
                id={`returnDay`}
                name={`returnDay`}
                label={`Return Day`}
                placeholder={`Return Day`}
                errorMessage={`Enter Return Day`}
              />
            </div>

          </div>
          <div className="row m20-top formFooter">
            <div className="col-12 text-center m0">
              <Button
                type={`submit`}
                className={`bg-second text-light m10-left`}
                text={`Update`}
              />
            </div>
          </div>
        </form>
      </div>

    </>
  );
}

export default GeneralSetting;
