import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiInstance } from "../../api/axiosApi";


const initialState = {
  order: [],
  orderTotal: 0,
  isLoading: false,
  isSkeleton: false,

};
export const orderAll = createAsyncThunk("order/orderAll", async (payload) => {
  return apiInstance.get(`order/orderAll?page=${payload.page}&limit=${payload.limit}&search=${payload.search}&sortField=${payload.sortField}&sortOrder=${payload.sortOrder}&status=${payload.status}`);
});

export const updateOrderStatus = createAsyncThunk("order/updateStatus", async (payload) => {
  return apiInstance.put(`order/updateStatus?orderId=${payload.orderId}&status=${payload.status}`);
});

export const paymentOrder = createAsyncThunk("payment/paymentOrder", async (payload) => {
  return apiInstance.get(`payment/paymentOrder?paymentOrderId=${payload.paymentOrderId}&paymentId=${payload.paymentId}`);
});


const orderSlice = createSlice({
  name: "orderSlice",
  initialState,
  reducers: {

  },
  extraReducers: (builder) => {
    // orderAll
    builder.addCase(orderAll.pending, (state, action) => {
      state.isSkeleton = action.meta.arg.command;
    });
    builder.addCase(orderAll.fulfilled, (state, action) => {

      state.order = action.payload.order;
      state.orderTotal = action.payload.orderTotal;
      state.isSkeleton = false;
    });
    builder.addCase(orderAll.rejected, (state, action) => {
      state.isSkeleton = false;
    });

    // updateOrderStatus
    builder.addCase(updateOrderStatus.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(updateOrderStatus.fulfilled, (state, action) => {
      const orderIdx = state.order.findIndex((order) => order._id === action.payload.order._id);
      if (orderIdx !== -1) {
        state.order[orderIdx] = { ...state.order[orderIdx], ...action.payload.order };
      }
      state.isLoading = false;
    });
    builder.addCase(updateOrderStatus.rejected, (state, action) => {
      state.isLoading = false;
    });

    // orderAll
    builder.addCase(paymentOrder.pending, (state, action) => {
      state.isSkeleton = action.meta.arg.command;
    });
    builder.addCase(paymentOrder.fulfilled, (state, action) => {

      state.order = action.payload.order;
      state.isSkeleton = false;
    });
    builder.addCase(paymentOrder.rejected, (state, action) => {
      state.isSkeleton = false;
    });

  },
});
export default orderSlice.reducer;

