import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Input, { Textarea } from "../../extra/Input";
import Button from "../../extra/Button";
import { editData, submitData } from "../../util/fuction";
import { closeDialog } from "../../../redux/slice/dialogSlice";
import {
  productCareAdd,
  productCareUpdate,
} from "../../../redux/slice/productCareSlice";

const ProductCareDialogue = ({ data, setData }) => {
  const { dialogueData } = useSelector((state) => state.dialogue);

  useEffect(() => {
    if (dialogueData) {
      editData(dialogueData);
    }
  }, [dialogueData]);

  const handleSubmit = async (e) => {
    const addProductCare = submitData(e);
    if (addProductCare) {
      try {
        let response;
        if (dialogueData) {
          const payload = { addProductCare, productCareId: dialogueData._id };
          response = await dispatch(productCareUpdate(payload)).unwrap();
        } else {
          response = await dispatch(productCareAdd(addProductCare)).unwrap();
        }
        console.log(response.status, "response.data.status");
        response.status ? dispatch(closeDialog()) : alert(response.message);
      } catch (err) {
        console.log("err", err);
        alert(err.message);
      }
    }
  };

  const dispatch = useDispatch();
  return (
    <div className="dialog">
      <div className="w-100">
        <div className="row justify-content-center">
          <div className="col-xl-5 col-md-8 col-11">
            <div className="mainDiaogBox">
              <div className="row justify-content-between align-items-center formHead">
                <div className="col-8">
                  <h2 className="text-second m0">ProductCare Dialog</h2>
                </div>
                <div className="col-4">
                  <div
                    className="closeButton"
                    onClick={() => {
                      dispatch(closeDialog());
                    }}
                  >
                    <i className="ri-close-line"></i>
                  </div>
                </div>
              </div>
              <form onSubmit={handleSubmit} id="productCareForm">
                <div className="row align-items-start formBody">
                  <div className="col-12">
                    <Input
                      type={`text`}
                      id={`title`}
                      name={`title`}
                      label={`Title`}
                      placeholder={`Title`}
                      errorMessage={`Enter Title`}
                    />
                  </div>
                </div>
                <div className="row m20-top formFooter">
                  <div className="col-12 text-end m0">
                    <Button
                      className={`bg-gray text-light`}
                      text={`Cancel`}
                      type={`button`}
                      onClick={() => dispatch(closeDialog())}
                    />
                    <Button
                      type={`submit`}
                      className={`bg-second text-light m10-left`}
                      text={`Submit`}
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductCareDialogue;
