import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiInstance } from "../../api/axiosApi";

const initialState = {
  productCare: [],
  isLoading: false,
  isSkeleton: false,
};
export const productCareGet = createAsyncThunk("productCare/show", async () => {
  return apiInstance.get("productCare/show");
});
export const productCareAdd = createAsyncThunk(
  "productCare/create",
  async (payload) => {
    return apiInstance.post("productCare/create", payload);
  }
);
export const productCareUpdate = createAsyncThunk(
  "productCare/update",
  async (payload) => {
    return apiInstance.patch(
      `productCare/update?productCareId=${payload.productCareId}`,
      payload.addProductCare
    );
  }
);
export const productCareDelete = createAsyncThunk(
  "productCare/delete",
  async (id) => {
    return apiInstance.delete(`productCare/delete?productCareId=${id}`);
  }
);

const productCareSlice = createSlice({
  name: "productCareSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // productCareGet
    builder.addCase(productCareGet.pending, (state, action) => {
      state.isSkeleton = true;
    });
    builder.addCase(productCareGet.fulfilled, (state, action) => {
      state.productCare = action.payload.productCare;
      state.isSkeleton = false;
    });
    builder.addCase(productCareGet.rejected, (state, action) => {
      state.isSkeleton = false;
    });

    // productCareCreate
    builder.addCase(productCareAdd.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(productCareAdd.fulfilled, (state, action) => {
      state.productCare.unshift(action.payload.productCare);
      state.isLoading = false;
    });
    builder.addCase(productCareAdd.rejected, (state, action) => {
      state.isLoading = false;
    });

    // productCareUpdate
    builder.addCase(productCareUpdate.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(productCareUpdate.fulfilled, (state, action) => {
      const productCareIdx = state.productCare.findIndex(
        (productCare) => productCare._id === action.payload.productCare._id
      );
      if (productCareIdx !== -1) {
        state.productCare[productCareIdx] = {
          ...state.productCare[productCareIdx],
          ...action.payload.productCare,
        };
      }
      state.isLoading = false;
    });
    builder.addCase(productCareUpdate.rejected, (state, action) => {
      state.isLoading = false;
    });

    // productCareDelete
    builder.addCase(productCareDelete.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(productCareDelete.fulfilled, (state, action) => {
      state.productCare = state.productCare.filter(
        (productCare) => productCare._id !== action.meta.arg
      );
      state.isLoading = false;
    });
    builder.addCase(productCareDelete.rejected, (state, action) => {
      state.isLoading = false;
    });
  },
});
export default productCareSlice.reducer;
