import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiInstance } from "../../api/axiosApi";


const initialState = {
  user: [],
  userProfileShow: {},
  order: [],
  isLoading: false,
  isSkeleton: false,

};
export const userAll = createAsyncThunk("user/userAll", async () => {
  return apiInstance.get("user/userAll");
});
export const userProfile = createAsyncThunk("user/userProfile", async (payload) => {
  return apiInstance.get(`user/userProfile?userId=${payload}`);
});
export const userUpdate = createAsyncThunk("user/updateUser", async (payload) => {
  return apiInstance.patch(`user/updateUser?userId=${payload.userId}`, payload.formData);
});
export const userBlock = createAsyncThunk("user/userBlock", async (payload) => {
  return apiInstance.put(`user/userBlock?userId=${payload}`);
});



export const userOrder = createAsyncThunk("order/userOrder", async (payload) => {
  return apiInstance.get(`order/userOrder?userId=${payload}`);
});

const userSlice = createSlice({
  name: "userSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // userAll
    builder.addCase(userAll.pending, (state, action) => {
      state.isSkeleton = true;
    });
    builder.addCase(userAll.fulfilled, (state, action) => {
  
      state.user = action.payload.user;
      state.isSkeleton = false;
    });
    builder.addCase(userAll.rejected, (state, action) => {
      state.isSkeleton = false;
    });
    // userProfile
    builder.addCase(userProfile.pending, (state, action) => {
      state.isSkeleton = true;
    });
    builder.addCase(userProfile.fulfilled, (state, action) => {

      state.userProfileShow = action.payload.user;
      state.isSkeleton = false;
    });
    builder.addCase(userProfile.rejected, (state, action) => {
      state.isSkeleton = false;
    });

    // userUpdate
    builder.addCase(userUpdate.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(userUpdate.fulfilled, (state, action) => {
      const userIdx = state.user.findIndex((user) => user._id === action.payload.user._id);
      if (userIdx !== -1) {
        state.user[userIdx] = { ...state.user[userIdx], ...action.payload.user };
      }
      state.isLoading = false;
    });
    builder.addCase(userUpdate.rejected, (state, action) => {
      state.isLoading = false;
    });


    // userBlock
    builder.addCase(userBlock.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(userBlock.fulfilled, (state, action) => {
      const userIdx = state.user.findIndex((user) => user._id === action.payload.user._id);
      if (userIdx !== -1) {
        state.user[userIdx] = { ...state.user[userIdx], ...action.payload.user };
      }
      state.isLoading = false;
    });
    builder.addCase(userBlock.rejected, (state, action) => {
      state.isLoading = false;
    });


     // userOrder
     builder.addCase(userOrder.pending, (state, action) => {
      state.isSkeleton = true;
    });
    builder.addCase(userOrder.fulfilled, (state, action) => {
      if (action?.payload?.status) {
        state.order = action.payload.order;
        state.isSkeleton = false;
      }
    });
    builder.addCase(userOrder.rejected, (state, action) => {
      state.isSkeleton = false;
    });
  
  },
});
export default userSlice.reducer;

