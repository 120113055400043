import React, { useEffect, useState } from "react";
import Title from "../../extra/Title";
import { useDispatch, useSelector } from "react-redux";
import {
  editData,
  generateNum,
  objectToFormData,
  submitData,
} from "../../util/fuction";
import { categoryGet } from "../../../redux/slice/categorySlice";
import { attributesGet } from "../../../redux/slice/attributesSlice";
import Input, { Image, MultiSelect, Select } from "../../extra/Input";
import Button from "../../extra/Button";
import { productAdd } from "../../../redux/slice/productSlice";
import { useLocation, useNavigate } from "react-router-dom";
import { closeDialog } from "../../../redux/slice/dialogSlice";
import { setToast } from "../../extra/toast";

const ProductAdd = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { category } = useSelector((state) => state.category);
  const { attributes } = useSelector((state) => state.attributes);
  useEffect(() => {
    dispatch(categoryGet({ command: false }));
    dispatch(attributesGet({ command: false }));
  }, []);

  console.log("location", location);
  const [arrayCount, setArrayCount] = useState(1);
  const [arrayCustomCount, setArrayCustomCount] = useState(arrayCount);

  useEffect(() => {
    if (location?.state) {
      editData(location?.state);
    }
  }, [location?.state]);

  const [sizes, setSizes] = useState({});
  const [selectSizes, setSelectSizes] = useState({});

  const handleChangeValue = (value, names) => {
    setSelectSizes({ ...selectSizes, [names]: value });
  };

  const handleRemoveSizeData = (objIndex, removeIndex) => {
    const objeSize = `size${objIndex}`;
    const objeStock = `stock${objIndex}`;

    // Get the current size array and stock value
    const currentSizes = sizes[objeSize] || [];
    const currentStock = sizes[objeStock] || 0;

    if (removeIndex < 0 || removeIndex >= currentSizes.length) {
      setToast("error", "Invalid index");
      return;
    }

    // Get the stock value of the size being removed
    const removedStock = currentSizes[removeIndex].sizeStock;

    // Remove the size entry at the given Index
    const updatedSizes = currentSizes.filter((_, i) => i !== removeIndex);
    const updatedStock = currentStock - removedStock;

    setSizes((prev) => ({
      ...prev,
      [objeSize]: updatedSizes,
      [objeStock]: updatedStock <= 0 ? "" : updatedStock,
    }));
  };

  const handleMoveSizeData = (storeId) => {
    if (!selectSizes[`sizeName${storeId}`] || !selectSizes[`sizeStock${storeId}`]) {
      setToast("error", "Enter Size or Stock");
    } else {
      const objeSize = `size${storeId}`;
      const objeStock = `stock${storeId}`;

      // Get the current size array and stock value
      const currentSizes = sizes[objeSize] || [];
      const currentStock = sizes[objeStock] || 0;

      // Update the size array and stock value
      const updatedSizes = [
        ...currentSizes,
        { sizeName: selectSizes[`sizeName${storeId}`], sizeStock: Number(selectSizes[`sizeStock${storeId}`]) },
      ];
      const updatedStock = currentStock + Number(selectSizes[`sizeStock${storeId}`]);
      setSizes((prev) => ({
        ...prev,
        [objeSize]: updatedSizes,
        [objeStock]: updatedStock,
      }));

      // Reset selectSizes after moving data
      setSelectSizes({ ...selectSizes, [`sizeName${storeId}`]: "", [`sizeStock${storeId}`]: "" });
    }
  };

  const handleSubmit = async (e) => {
    const addProduct = submitData(e);
    console.log("addProduct", addProduct);

    // Update or New Index Value
    const arrayNewCount = [];
    const newTest = e.target.querySelectorAll("#mainMultiSelector")[0].children;
    for (let i = 0; i < newTest.length; i++) {
      arrayNewCount.push(newTest[i].getAttribute("data-tabIndex"));
    }

    if (addProduct) {
      // multiple images array of [array object]

      // Stock and aize Data get me in State (sizes)
      const newSizeArray = {
        size: Array.from(
          { length: arrayNewCount.length },
          (_, i) => sizes[`size${arrayNewCount[i]}`]
        ),
      };
      const newStockArray = {
        stock: Array.from(
          { length: arrayNewCount.length },
          (_, i) => sizes[`stock${arrayNewCount[i]}`]
        ),
      };


      const newImageArray = {
        profileImage: Array.from(
          { length: arrayNewCount.length },
          (_, i) => addProduct[`images${arrayNewCount[i]}`]
        ),
      };
      const newColorArray = {
        color: Array.from(
          { length: arrayNewCount.length },
          (_, i) => addProduct[`color${arrayNewCount[i]}`]
        ),
      };

      const newSkuArray = {
        sku: Array.from(
          { length: arrayNewCount.length },
          (_, i) => addProduct[`sku${arrayNewCount[i]}`]
        ),
      };
      const newBSizeArray = {
        sizeB: Array.from(
          { length: arrayNewCount.length },
          (_, i) => addProduct[`sizeB${arrayNewCount[i]}`]
        ),
      };
      const newBColorArray = {
        colorB: Array.from(
          { length: arrayNewCount.length },
          (_, i) => addProduct[`colorB${arrayNewCount[i]}`]
        ),
      };

      const customObj = {
        title: addProduct.title,
        febric: addProduct.febric,
        categoryId: addProduct.categoryId,
        oldPrice: addProduct.oldPrice,
        price: addProduct.price,
        shippingCharge: addProduct.shippingCharge,
        productCode: addProduct.productCode,
        craft: addProduct.craft,
        work: addProduct.work,
        patten: addProduct.patten,
        sku: addProduct.sku,
        purity: addProduct.purity,
        ...newSizeArray,
        ...newStockArray,
        ...newImageArray,
        ...newColorArray,
        ...newBSizeArray,
        ...newBColorArray,
        ...newSkuArray,
        length: addProduct.length,
        breadth: addProduct.breadth,
        height: addProduct.height,
        weight: addProduct.weight,
      };

      console.log("customObj", customObj);

      const formData = objectToFormData(customObj);

      try {
        if (formData) {
          let response = await dispatch(productAdd(formData)).unwrap();
          console.log(response.status, "response.data.status");
          if (response.status) {
            dispatch(closeDialog());
            navigate(-1);
          } else {
            alert(response.message);
          }
        }
      } catch (err) {
        console.log("err", err);
        alert(err.message);
      }
    }
  };

  const option = category.map((res) => {
    return { name: res.categoryName, value: res._id };
  });

  const attributeNames = ["Color", "Size", "Febric"];
  const [colorData, sizeData, febricData] = attributeNames.map((attrName) =>
    attributes.find((obj) => obj["attrName"] === attrName)
  );

  const handleRemoveInnerHTML = (index) => {
    // Remove the element at the specified index
    const element = document.querySelector(`[data-tabIndex="${index}"]`);
    if (element) {
      element.remove();
      setArrayCustomCount(arrayCustomCount - 1);
      setSizes(prevSizes => {
        const newSizes = { ...prevSizes };
        delete newSizes[`size${index}`];
        delete newSizes[`stock${index}`];
        return newSizes;
      });
    }
  };

  return (
    <div className="">
      <Title name={"Add Product"} />

      <div className="mainAddProduct">
        <form
          onSubmit={handleSubmit}
          id="productForm"
          className="position-relative p10-x"
        >
          <div className="row align-items-start formBody">
            <div className="col-lg-6 col-12">
              <Input
                type={`text`}
                id={`title`}
                name={`title`}
                label={`Title`}
                placeholder={`Title`}
                errorMessage={`Enter Title`}
              />
            </div>
            <div className="col-lg-6 col-12">
              <Select
                option={febricData?.details}
                className={`inputSelect`}
                id={`febric`}
                name={`febric`}
                label={`Febric`}
                placeholder={`Select Febric`}
                errorMessage={`Select Febric`}
              />
            </div>
            <div className="col-lg-3 col-6">
              <Input
                type={`text`}
                id={`craft`}
                name={`craft`}
                label={`Craft`}
                placeholder={`Craft`}
                errorMessage={`Enter Craft`}
              />
            </div>
            <div className="col-lg-3 col-6">
              <Input
                type={`text`}
                id={`work`}
                name={`work`}
                label={`Work`}
                placeholder={`Work`}
                errorMessage={`Enter Work`}
              />
            </div>
            <div className="col-lg-3 col-6">
              <Input
                type={`text`}
                id={`patten`}
                name={`patten`}
                label={`Patten`}
                placeholder={`Patten`}
                errorMessage={`Enter Patten`}
              />
            </div>
            <div className="col-lg-3 col-6">
              <Input
                type={`text`}
                id={`purity`}
                name={`purity`}
                label={`Purity`}
                placeholder={`Purity`}
                errorMessage={`Enter Purity`}
              />
            </div>
            <div className="col-lg-4 col-6">
              <Input
                type={`number`}
                id={`oldPrice`}
                name={`oldPrice`}
                label={`Old Price`}
                placeholder={`Old Price`}
                errorMessage={`Enter Old Price`}
              />
            </div>

            <div className="col-lg-4 col-6">
              <Input
                type={`number`}
                id={`price`}
                name={`price`}
                label={`Price`}
                placeholder={`Price`}
                errorMessage={`Enter Old Price`}
              />
            </div>
            <div className="col-lg-4 col-12">
              <Input
                type={`number`}
                id={`shippingCharge`}
                name={`shippingCharge`}
                label={`Shipping  Charge`}
                placeholder={`Shipping  Charge`}
                errorMessage={`Enter Shipping  Charge`}
              />
            </div>

            <div className="col-lg-6 col-12">
              <Input
                type={`number`}
                id={`productCode`}
                name={`productCode`}
                label={`Product Code`}
                placeholder={`Product Code`}
                errorMessage={`Enter product Code`}
                validation={`^[a-zA-Z0-9]{8,8}$`}
                validationError={`Invalid Product Code`}
                activeIcon={`ri-information-line`}
                activClick={() => generateNum(8, "productCode")}
              />
            </div>
            <div className="col-lg-6 col-12">
              <Select
                option={option}
                className={`inputSelect`}
                id={`categoryId`}
                name={`categoryId`}
                label={`Category`}
                placeholder={`Select Category`}
                errorMessage={`Select Category`}
              />
            </div>
            <div className="col-lg-3 col-6">
              <Input
                type={`text`}
                id={`length`}
                name={`length`}
                label={`Length (DP)`}
                placeholder={`Length (DP)`}
                errorMessage={`Enter Length (DP)`}
              />
            </div>
            <div className="col-lg-3 col-6">
              <Input
                type={`text`}
                id={`breadth`}
                name={`breadth`}
                label={`Breadth (DP)`}
                placeholder={`Breadth (DP)`}
                errorMessage={`Enter Breadth (DP)`}
              />
            </div>
            <div className="col-lg-3 col-6">
              <Input
                type={`text`}
                id={`height`}
                name={`height`}
                label={`Height (DP)`}
                placeholder={`Height (DP)`}
                errorMessage={`Enter Height (DP)`}
              />
            </div>
            <div className="col-lg-3 col-6">
              <Input
                type={`text`}
                id={`weight`}
                name={`weight`}
                label={`Weight (DP)`}
                placeholder={`Weight (DP)`}
                errorMessage={`Enter Weight (DP)`}
              />
            </div>

            <div className="col-12 mainMultiSelector" id="mainMultiSelector">
              {Array(arrayCount)
                .fill()
                .map((res, index) => {
                  return (
                    <div className="row" data-tabIndex={index}>
                      {arrayCustomCount > 1 && (
                        <div
                          className="multiRemover"
                          onClick={() => handleRemoveInnerHTML(index)}
                        >
                          <i class="ri-delete-bin-5-line"></i>
                        </div>
                      )}

                      <div className="col-xxl-7 col-lg-6 col-md-4 col-12 order-md-0 order-1">
                        <div className="row">
                          <div className="col-xl-5 col-lg-6 col-12 order-0">
                            <Select
                              option={colorData?.details}
                              className={`inputSelect`}
                              id={`color${index}`}
                              name={`color${index}`}
                              label={`Color`}
                              placeholder={`Select Color`}
                              errorMessage={`Select Color`}
                              title={`color`}
                            />
                          </div>
                          <div className="col-xl-4 col-lg-6 col-12 order-1">
                            <Input
                              type={`text`}
                              id={`sku${index}`}
                              name={`sku${index}`}
                              label={`SKU Id`}
                              placeholder={`SKU Id`}
                              errorMessage={`Enter SKU Id`}
                            />
                          </div>
                          <div className="col-xl-3 col-12 order-xl-2 order-first">
                            <Input
                              type={`text`}
                              id={`stock${index}`}
                              name={`stock${index}`}
                              label={`Total Stock`}
                              placeholder={`Total Stock`}
                              errorMessage={`Select Size Stock`}
                              value={sizes && sizes[`stock${index}`]}
                              readOnly={true}
                            />
                          </div>
                          <div className="col-lg-6 col-12 order-3">
                            <Select
                              option={colorData?.details}
                              className={`inputSelect`}
                              id={`colorB${index}`}
                              name={`colorB${index}`}
                              label={`Blouse Color`}
                              placeholder={`Select Blouse Color`}
                              errorMessage={`Select Blouse Color`}
                              title={`color`}
                            />
                          </div>
                          <div className="col-lg-6 col-12 order-4">
                            <Select
                              option={sizeData?.details}
                              className={`inputSelect`}
                              id={`sizeB${index}`}
                              name={`sizeB${index}`}
                              label={`Blouse Size`}
                              placeholder={`Select Blouse Size`}
                              errorMessage={`Select Blouse Size`}
                            />
                          </div>
                          <div className="col-lg-7 col-12 order-5">
                            <Image
                              label={`Images`}
                              id={`images${index}`}
                              name={`images${index}`}
                              errorMessage={`Enter Images`}
                              multiple={true}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-xxl-5 col-lg-6 col-md-8 col-12">
                        <div className="row align-items-center">
                          <div className="col-xsm-5 col-12">
                            <Select
                              option={sizeData?.details}
                              className={`inputSelect`}
                              id={`sizeName${index}`}
                              name={`sizeName${index}`}
                              label={`Size`}
                              placeholder={`Select Size`}
                              errorMessage={`Select Size`}
                              onChange={(e) => handleChangeValue(e, `sizeName${index}`)}
                              dataValue={selectSizes[`sizeName${index}`]}
                              ignore={true}
                            />
                          </div>
                          <div className="col-xsm-5 col-10">
                            <Input
                              type={`number`}
                              id={`sizeStock${index}`}
                              name={`sizeStock${index}`}
                              label={`Stock`}
                              placeholder={`Stock`}
                              errorMessage={`Enter Stock`}
                              onChange={(e) =>
                                handleChangeValue(e.target.value, `sizeStock${index}`)
                              }
                              ignore={true}
                              value={selectSizes[`sizeStock${index}`]}
                            />
                          </div>
                          <div className="col-2 m-0">
                            <div className="primeButton">
                              <button
                                type="button"
                                onClick={() => handleMoveSizeData(index)}
                              >
                                +
                              </button>
                            </div>
                          </div>
                          <div className="col-12">
                            <div className="allSizes d-inline-flex flex-wrap w-100 fs-xl-16 fs-md-14 fs-12">
                              {
                                sizes[`size${index}`] && (
                                  sizes[`size${index}`]?.map((res, i) => (
                                    <div className="showSizes">
                                      <div
                                        className="removeSize"
                                        onClick={() =>
                                          handleRemoveSizeData(index, i)
                                        }
                                      >
                                        <i class="ri-close-line"></i>
                                      </div>
                                      <span className="text-success">
                                        {res.sizeName} -{" "}
                                      </span>
                                      <span className="text-blue">
                                        {" "}
                                        {res.sizeStock} Pieces
                                      </span>
                                    </div>
                                  ))
                                )
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
            <div className="col-md-4 col-7 m-auto">
              <Button
                className={`bg-success text-light w-100`}
                text={`Add More Color`}
                type={`button`}
                onClick={() => {
                  setArrayCount(arrayCount + 1);
                  setArrayCustomCount(arrayCustomCount + 1);
                }}
              />
            </div>
          </div>
          <div className="row formFooter">
            <div className="col-6 text-center m0">
              <Button
                className={`bg-gray text-light w-100`}
                text={`Cancel`}
                type={`button`}
              />
            </div>
            <div className="col-6 text-center m0">
              <Button
                type={`submit`}
                className={`bg-second text-light w-100`}
                text={`Submit`}
              />
            </div>
          </div>
        </form>
      </div>
      {/* var script = document.createElement("script")
    script.scr = "//cdn.jsdelivr.net/npm/eruda";
    document.body.appendChild(script)
    script.onload = (() => eruda.init()) */}

      {/* javascript:(function%20()%20%7B%20var%20script%20=%20document.createElement('script');%20script.src=%22//cdn.jsdelivr.net/npm/eruda%22;%20document.body.appendChild(script);%20script.onload%20=%20function%20()%20%7B%20eruda.init()%20%7D%20%7D)(); */}
    </div>
  );
};

export default ProductAdd;
