import React, { useEffect } from "react";
import Title from "../../extra/Title";
import Input, { Textarea } from "../../extra/Input";
import { closeDialog } from "../../../redux/slice/dialogSlice";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../extra/Button";
import { settingGet, settingUpdate } from "../../../redux/slice/settingSlice";
import { editData, objectToFormData, submitData } from "../../util/fuction";

const WebSetting = () => {
  const dispatch = useDispatch();
  const { setting } = useSelector((state) => state.setting);

  useEffect(() => {
    dispatch(settingGet());
  }, []);
  useEffect(() => {
    if (setting) editData(setting);
  }, [setting]);

  const handleSubmit = async (e) => {
    const updateSetting = submitData(e);
    if (updateSetting) {
      console.log("updateSetting", updateSetting);
      const formData = objectToFormData(updateSetting);
      if (formData) {
        await dispatch(settingUpdate(formData));
      }
    }
  };
  return (
    <>
      <Title name={`Web Setting`} />

      <div className="startSetting">
        <form onSubmit={handleSubmit} id="webSetting">
          <div
            className="row align-items-start formBody bg-light p20"
            style={{ boxShadow: "0 0 12px #0f223a1f" }}
          >
            <div className="col-md-6 col-12">
              <Input
                type={`text`}
                id={`headerOffer`}
                name={`headerOffer`}
                label={`Header Offer`}
                placeholder={`Header Offer`}
                errorMessage={`Enter Header Offer`}
              />
            </div>
            <div className="col-md-6 col-12">
              <Input
                type={`text`}
                id={`companyName`}
                name={`companyName`}
                label={`Company Name`}
                placeholder={`Company Name`}
                errorMessage={`Enter Company Name`}
              />
            </div>
            <div className="col-md-6 col-12">
              <Input
                type={`text`}
                id={`contact`}
                name={`contact`}
                label={`Contact`}
                placeholder={`Contact`}
                errorMessage={`Enter Contact`}
              />
            </div>
            <div className="col-md-6 col-12">
              <Input
                type={`text`}
                id={`email`}
                name={`email`}
                label={`Email`}
                placeholder={`Email`}
                errorMessage={`Enter Email`}
              />
            </div>
            <div className="col-md-6 col-12">
              <Textarea
                id={`address`}
                name={`address`}
                label={`Address`}
                row={4}
              />
            </div>
            <div className="col-md-6 col-12">
              <Textarea
                id={`shopTime`}
                name={`shopTime`}
                label={`Shop Time`}
                row={4}
              />
            </div>
            <div className="col-md-6 col-12">
              <Textarea
                id={`webText`}
                name={`webText`}
                label={`Web Text`}
                row={6}
              />
            </div>
            <div className="col-md-6 col-12">
              <Textarea
                id={`footerText`}
                name={`footerText`}
                label={`Footer Text`}
                row={6}
              />
            </div>
            <div className="col-md-6 col-12">
              <Textarea
                id={`menufacture`}
                name={`menufacture`}
                label={`Menufacture`}
                row={5}
              />
            </div>
          </div>
          <div className="row m20-top formFooter">
            <div className="col-12 text-center m0">
              <Button
                type={`submit`}
                className={`bg-second text-light m10-left`}
                text={`Update`}
              />
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default WebSetting;
